/* Center the form container vertically and horizontally */
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #f2f2f2;
    padding: 1rem;
  }
  
  /* Style the login form */
  .login-form {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .login-form input {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .login-form button {
    width: 100%;
    padding: 0.75rem;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .login-form button:hover {
    background: #0056b3;
  }
  
  .message {
    margin-top: 1rem;
    text-align: center;
    color: #d9534f;
  }
  
  .toggle-text {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #555;
  }
  
  .toggle-link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  