/* Header layout */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  flex-wrap: nowrap;
}

/* Left section */
.header-left h1 {
  margin: 0;
  font-size: 1.8rem;
  color: #333;
}

.subtitle {
  font-size: 1rem;
  color: #666;
}

/* Right section */
.header-right {
  display: flex;
  align-items: center;
}

/* Hamburger button - hidden on desktop */
.hamburger {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
}

/* Navigation - visible on desktop */
.nav ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.nav li a,
.logout-button {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 1.2em;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav li a:hover,
.logout-button:hover {
  color: #007bff;
}

/* Responsive for mobile */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fdf4f4;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 999;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.4s ease, opacity 0.4s ease;
  }

  .nav.active {
    max-height: 500px; /* enough to show all links */
    opacity: 1;
  }

  .nav ul {
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    margin: 0;
    gap: 0.5rem;
  }

  .nav li {
    width: 100%;
    padding: 0.75rem 1rem;
    margin: 0;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .nav li:last-child {
    border-bottom: none;
  }

  .nav li a,
  .logout-button {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    background: none;
    border: none;
    width: 100%;
    text-align: center;
    transition: background 0.3s ease, color 0.3s ease;
  }

  .nav li a:hover,
  .logout-button:hover {
    /* background-color: #f8f8f8; */
    color: #007bff;
  }

  .logout-button {
    text-align: center;
  }
}