/* Headings */
h2 {
  text-align: center;
  font-size: 1.25em;
  margin-bottom: 1rem;
}

/* Container */
.product-list-container {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Error message */
.error-message {
  color: red;
  margin-bottom: 1rem;
  text-align: center;
}

/* ============================= */
/* SEARCH BAR */
/* ============================= */
.search-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10vh;
}

.search-bar input {
  flex: 1 1 100%;
  max-width: 800px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.search-bar button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-bar button:hover {
  background-color: #555;
}

/* ============================= */
/* FILTER BAR */
/* ============================= */
.filter-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  background-color: #f8f8f8;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.filter-bar input,
.filter-bar select,
.filter-bar button {
  padding: 0.6rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  flex: 1 1 180px;
  max-width: 220px;
}

.filter-bar button {
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s ease;
}

.filter-bar .apply-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.filter-bar .apply-btn:hover {
  background-color: #0056b3;
}

.filter-bar .reset-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
}

.filter-bar .reset-btn:hover {
  background-color: #c62828;
}

/* Space between stacked inputs on mobile */
.filter-bar select:not(:last-child),
.filter-bar input:not(:last-child) {
  margin-bottom: 0.5rem;
}

/* ============================= */
/* PRODUCT CARDS */
/* ============================= */
.product-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}

.product-card {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 1rem;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.product-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.product-link {
  text-decoration: none;
  color: inherit;
}

.product-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.product-card h4 {
  font-size: 1rem;
  margin: 0.2rem 0;
  font-weight: 600;
}

.product-card .price {
  font-size: 1.1rem;
  font-weight: bold;
  color: #000;
  margin: 0.5rem 0;
}

.product-card p {
  font-size: 0.85rem;
  color: #666;
}

/* ============================= */
/* PAGINATION */
/* ============================= */
.pagination {
  margin-top: 10vh;
  text-align: center;
}

.page-btn {
  margin: 0 5px;
  padding: 0.9em 1rem;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background 0.2s ease;
}

.page-btn:hover {
  background-color: #ddd;
}

.page-btn.active {
  background-color: #333;
  color: #fff;
}

/* ============================= */
/* RESPONSIVE BREAKPOINTS */
/* ============================= */
@media (max-width: 1200px) {
  .product-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .product-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .filter-bar input,
  .filter-bar select,
  .filter-bar button {
    width: 20%;
    max-width: 20%;
  }
}

@media (max-width: 480px) {
  .product-cards {
    grid-template-columns: 1fr;
  }

  .search-bar {
    flex-direction: column;
    gap: 0.75rem;
  }

  .search-bar input,
  .search-bar button {
    width: 100%;
    max-width: 100%;
  }

  .filter-bar input,
  .filter-bar select,
  .filter-bar button {
    width: 20%;
    max-width: 20%;
  }

}
