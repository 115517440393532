/* Center the form container vertically and horizontally */
.register-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #f2f2f2;
    padding: 1rem;
  }
  
  /* Style the registration form */
  .register-form {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .register-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .register-form input,
  .register-form select,
  .register-form label {
    width: 100%;
    display: block;
    padding: 0.75rem;
    margin: 0.5rem 0;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .register-form input[type="file"] {
    padding: 0;
    border: none;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .button-group button {
    flex: 1;
    margin: 0 0.25rem;
    padding: 0.75rem;
    background: #649219;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .button-group button:hover {
    background: #0056b3;
  }
  
  .message {
    margin-top: 1rem;
    text-align: center;
    color: #28a745;
  }
  
  .toggle-text {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #555;
  }
  
  .toggle-link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  