
.user-profile {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.user-profile img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.user-profile p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.product-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 250px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-card img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.error-message {
  color: red;
  text-align: center;
}
