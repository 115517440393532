.product-detail-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.product-detail-left {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-detail-left img {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
}

.product-detail-right {
  flex: 2;
  min-width: 300px;
}

.product-detail-right h1 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.price-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.price {
  font-size: 1.8rem;
  color: #e60023;
  font-weight: bold;
}

.original-price {
  text-decoration: line-through;
  color: #999;
}

.discount {
  background: #ffcccc;
  color: #d00;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-weight: bold;
}

.detail-line {
  margin-bottom: 0.5rem;
}

.product-description {
  margin-top: 1.5rem;
}

.product-description h4 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.product-detail-buttons {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row; /* stays side-by-side on large screens */
}

.btn-outline-black,
.btn-filled-black {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  min-width: 180px;
  flex: 1;
  text-align: center;
  border-radius: 12px;
}

.btn-outline-black {
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
}

.btn-filled-black {
  background-color: #000;
  color: #fff;
  border: none;
}

.btn-outline-black:hover {
  background-color: #000;
  color: #fff;
}

.btn-filled-black:hover {
  background-color: #333;
}

.store-card {
  max-width: 1200px;
  margin: 1rem auto;
  padding: 1.5rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.store-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.store-logo {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background: #ffe4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: #d33;
}

.store-info h3 {
  margin: 0;
  font-size: 1.2rem;
}

.store-tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 0.4rem;
}

.tag {
  font-size: 0.85rem;
  background: #f9f1ee;
  color: #8a4a2d;
  padding: 0.2rem 0.6rem;
  border-radius: 6px;
}

.store-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.btn-store-outline {
  padding: 0.6rem 1.2rem;
  background: #fff;
  color: #e60023;
  border: 1px solid #e60023;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease;
  text-decoration: none;
}

.btn-store-outline:hover {
  background: #e60023;
  color: white;
}


@media (max-width: 768px) {
  .product-detail-wrapper {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .product-detail-buttons {
    flex-direction: column;
    gap: 0.75rem;
  }

  .btn-outline-black,
  .btn-filled-black {
    width: 100%;
  }
}