.landing-page {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .hero-banner {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .hero-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
    z-index: 0;
  }
  
  .hero-slide.active {
    opacity: 1;
    z-index: 1;
  }
  
  .hero-text {
    position: absolute;
    bottom: 10%;
    left: 5%;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    padding: 1rem 1.5rem;
    border-radius: 8px;
    z-index: 2;
    max-width: 90%;
  }
  
  .hero-text h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  .hero-text p {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .hero-text h1 {
      font-size: 2rem;
    }
    .hero-text p {
      font-size: 1rem;
    }
  }
  