/* MyProductsList.css */
.my-products-container {
  padding: 1rem;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

.product-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  padding: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease;
  overflow: hidden;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.product-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-body {
  padding: 0.75rem 1rem;
  text-align: left;
}

.product-card h3 {
  margin: 0.5rem 0 0.25rem;
  font-size: 1rem;
}

.product-card .price {
  color: #e60023;
  font-weight: bold;
  font-size: 1.1rem;
  margin: 0;
}

.icon-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0.75rem 0;
  border-top: 1px solid #eee;
  background: #f9f9f9;
}

.icon {
  cursor: pointer;
  font-size: 1.2rem;
}

.edit-icon {
  color: #007bff;
}

.delete-icon {
  color: #dc3545;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
}

.modal-content h3 {
  margin-bottom: 1rem;
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.save-btn,
.cancel-btn {
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  margin-right: 0.5rem;
  cursor: pointer;
}

.save-btn {
  background-color: #28a745;
  color: white;
}

.cancel-btn {
  background-color: #ccc;
  color: #333;
}

@media (max-width: 768px) {
  .product-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .product-cards {
    grid-template-columns: 1fr;
  }
}
